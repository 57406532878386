import "./App.scss";
import "./css/main.css";
import "url-search-params-polyfill";

import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import translate, { TranslatorContext } from "./translate";
import { BreakpointsProvider } from "./hooks";
import AppHeader from "./components/app-header/AppHeader";
import loadable from "@loadable/component";
import { inPdfMode } from "./constants";

const LoadableExplorerContainer = loadable(() =>
    import(/* webpackChunkName: "ExplorerContainer" */ "./components/explorer/ExplorerContainer"),
);
const LoadableAtlas = loadable(() => import(/* webpackChunkName: "Atlas" */ "./components/atlas/Atlas"));
const LoadableRouteContainer = loadable(() =>
    import(/* webpackChunkName: "RouteContainer" */ "./components/route/RouteContainer"),
);
const LoadablePlannerContainer = loadable(() =>
    import(/* webpackChunkName: "PlannerContainer" */ "./components/planner/PlannerContainer"),
);
const LoadableStopFinderContainer = loadable(() =>
    import(/* webpackChunkName: "StopFinderContainer" */ "./components/stop-finder/StopFinderContainer"),
);
const LoadableRouteExplorerContainer = loadable(() =>
    import(/* webpackChunkName: "RouteExplorerContainer" */ "./components/route-explorer/RouteExplorerContainer"),
);

/**
 * URL parameters to modify behavior of the App
 *
 * @param {string} lang - Lang for the app, can be "en" or "fr"
 * @param {string} id - Id of itinerary
 * @param {string} type - "atlas" to show the Atlas, by default the Itinerary is shown
 * @param {string} showHeader - If the value is "true" the AppHeader is shown
 */
const urlParams = new URLSearchParams(window.location.search);
const cachedLang = urlParams.get("lang");
const cachedId = urlParams.get("id");
const cachedType = urlParams.get("type");
const cachedShowHeader = urlParams.get("showHeader");
const cachedTargetItinerary = urlParams.get("targetItinerary");
const cachedInLauncher = urlParams.get("inLauncher");
const cachedInLauncherModal = urlParams.get("inModal");
const cachedKey = urlParams.get("key");

export const useTranslator = defaultLang => {
    const [translator, setTranslator] = useState(() => () => "");
    const lang = (cachedLang || defaultLang).toLowerCase();
    useEffect(() => {
        setTranslator(() => translate(lang));
    }, [lang]);
    return translator;
};

function App(props) {
    const [inExplorer, setInExplorer] = useState();
    const translator = useTranslator(props.lang);
    const id = cachedId || props.id;
    const type = (cachedType || props.type).toLowerCase();
    const showHeader = (cachedShowHeader || props.header).toLowerCase() === "true";
    const inLauncher = cachedInLauncher?.toLowerCase() === "true";
    const inLauncherModal = cachedInLauncherModal?.toLowerCase() === "true";
    const targetItinerary = cachedTargetItinerary;
    const key = cachedKey;
    const rootRoute = useRouteMatch("/");
    const routesRoute = useRouteMatch("/routes");
    const itinerariesRoute = useRouteMatch("/itineraries");
    const placesRoute = useRouteMatch("/places");

    useEffectOnce(() => {
        let inExplorer = false;
        if ((rootRoute?.isExact && !type && !id) || routesRoute || itinerariesRoute || placesRoute) {
            inExplorer = true;
        }
        setInExplorer(inExplorer);
    });

    if (inExplorer === undefined) {
        return null;
    }

    return (
        <BreakpointsProvider>
            <TranslatorContext.Provider value={translator}>
                {inExplorer && <LoadableExplorerContainer></LoadableExplorerContainer>}
                {!inExplorer && showHeader && type !== "atlas" && <AppHeader />}
                {!inExplorer && (
                    <main className="trpr__base_container">
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={() => {
                                    if (type === "atlas") {
                                        return <LoadableAtlas atlasId={id} />;
                                    }
                                    if (type === "route") {
                                        return (
                                            <LoadableRouteContainer
                                                id={id}
                                                inLauncher={inLauncher}
                                                inLauncherModal={inLauncherModal}
                                            />
                                        );
                                    }
                                    return (
                                        <LoadablePlannerContainer
                                            itineraryId={id}
                                            inLauncher={inLauncher}
                                            inLauncherModal={inLauncherModal}
                                            inPdfMode={inPdfMode}
                                        />
                                    );
                                }}
                            />
                            <Route path="/explorer" component={LoadableRouteExplorerContainer} />
                            <Route
                                path="/stop-finder"
                                render={() => {
                                    return (
                                        <LoadableStopFinderContainer
                                            targetItinerary={targetItinerary}
                                            targetKey={key}
                                        />
                                    );
                                }}
                            />
                        </Switch>
                    </main>
                )}
            </TranslatorContext.Provider>
        </BreakpointsProvider>
    );
}

App.defaultProps = {
    type: "itinerary",
};

export default App;
